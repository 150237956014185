<script setup lang="ts">
import { VisuallyHidden } from 'radix-vue';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import IconButton from '@/components/button/IconButton.vue';
import { useZindex } from '@/hooks/useZindex';
import IconTimes from '@/icons/line/times.svg';
import { TransitionChild, TransitionRoot } from '@headlessui/vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    subtitle?: string;
    icon?: Component;
    screenReaderDescription?: string;
    allowClickaway?: boolean;
    isOpen?: boolean;
    isScrollable?: boolean;
    size?: 'sm' | 'md' | 'lg';
    backgroundColor?: 'white' | 'slate';
  }>(),
  {
    isOpen: false,
    isScrollable: true,
    allowClickaway: true,
    screenReaderDescription: '',
    size: 'sm',
    backgroundColor: 'white'
  }
);

const emit = defineEmits<{
  onClose: [void];
}>();

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const target = ref(null);
const { activate, deactivate } = useFocusTrap(target, { allowOutsideClick: true });

onMounted(() => {
  if (props.isOpen) {
    nextTick(() => {
      activate();
    });
  }
});

watch(
  () => props.isOpen,
  (isOpen) => {
    if (isOpen) {
      nextTick(() => {
        activate();
      });
    } else {
      deactivate();
    }
  }
);

const sizeClass = computed(
  () =>
    ({
      sm: 'max-w-[432px]',
      md: 'max-w-[600px]',
      lg: 'max-w-[800px]'
    })[props.size]
);

function onOverlayClick() {
  if (props.allowClickaway) {
    closeSlideout();
  }
}

function closeSlideout() {
  emit('onClose');
}

const rawIcon = toRaw(props.icon);
</script>

<template>
  <teleport to="body">
    <TransitionRoot
      appear
      :show="isOpen"
      :data-state="isOpen ? 'open' : 'closed'"
      ariaLive="assertive"
      class="isolate"
    >
      <TransitionChild
        as="template"
        enter="ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-black/70" @click="onOverlayClick" :style="{ zIndex }" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="transform transition ease-in-out duration-500"
        enterFrom="translate-x-full"
        enterTo="translate-x-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-x-0"
        leaveTo="translate-x-full"
      >
        <div
          ref="target"
          class="fixed inset-0 left-auto flex h-full w-full flex-col bg-white"
          :class="[sizeClass]"
          :style="{ zIndex }"
        >
          <slot name="content">
            <div class="relative z-10 bg-white">
              <div class="absolute right-0 top-0 z-20 flex w-full justify-end">
                <IconButton
                  :icon="IconTimes"
                  ariaLabel="Close slideout"
                  variant="invisible"
                  @click="closeSlideout"
                />
              </div>
              <div class="border-b border-slate-200">
                <div class="px-4 pb-8 pt-14">
                  <div class="flex w-full flex-col items-center justify-center gap-4">
                    <div class="flex flex-col items-center justify-center gap-2">
                      <div
                        v-if="rawIcon"
                        class="flex h-14 w-14 items-center justify-center rounded-full bg-slate-100"
                      >
                        <component :is="rawIcon" class="h-5 w-5 text-slate-500" />
                      </div>
                      <div class="flex flex-col items-center gap-y-1">
                        <slot name="title">
                          <div class="text-center text-base font-semibold leading-8 text-slate-900">
                            {{ title }}
                          </div>
                        </slot>
                        <template v-if="$slots.subtitle || subtitle">
                          <slot name="subtitle">
                            <p class="text-sm font-normal leading-4">{{ subtitle }}</p>
                          </slot>
                        </template>
                      </div>
                      <div v-if="$slots.badge" class="flex justify-center">
                        <slot name="badge" />
                      </div>
                    </div>
                    <div class="flex justify-center gap-2">
                      <slot name="actions" />
                    </div>
                  </div>
                  <VisuallyHidden>
                    <p>{{ screenReaderDescription }}</p>
                  </VisuallyHidden>
                </div>
              </div>

              <div v-if="$slots.subNav">
                <slot name="subNav" />
              </div>
            </div>

            <div
              class="flex-1 p-4"
              :class="{
                'bg-white': backgroundColor === 'white',
                'bg-slate-50': backgroundColor === 'slate',
                'overflow-y-auto': isScrollable,
                'overflow-y-hidden': !isScrollable
              }"
            >
              <slot></slot>
            </div>
          </slot>
        </div>
      </TransitionChild>
    </TransitionRoot>
  </teleport>
</template>
