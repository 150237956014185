<script setup lang="ts">
import { RouterLink } from 'hybridly/vue';
import { ButtonColor, ButtonSize, ButtonVariant, useButtonClasses } from '@/hooks/useButtonClasses';
import SpinnerIcon from '@/icons/line/spinner.svg';

type RouterLinkOptions = ExtractPropTypes<typeof RouterLink>['options'];

export type ButtonProps = {
  color?: ButtonColor | 'primary' | 'secondary' | 'slate' | 'warning' | 'danger' | 'success';
  href?: string;
  hrefOptions?: RouterLinkOptions;
  iconLeft?: Component;
  iconRight?: Component;
  isDisabled?: boolean;
  isExternalLink?: boolean;
  isLoading?: boolean;
  size?: ButtonSize | 'lg' | 'md' | 'sm' | 'xs';
  type?: 'button' | 'submit' | 'reset';
  useMediaQuery?: boolean;
  variant?: ButtonVariant | 'solid' | 'soft' | 'outlined' | 'invisible';
};

const props = withDefaults(defineProps<ButtonProps>(), {
  type: 'button',
  color: ButtonColor.primary,
  size: ButtonSize.md,
  variant: ButtonVariant.solid,
  isLoading: false,
  isDisabled: false,
  isExternalLink: false,
  useMediaQuery: false
});

const rawIconLeft = computed(() => (props.isLoading ? SpinnerIcon : props.iconLeft));
const rawIconRight = toRaw(props.iconRight);

const computedButtonComponent = computed(() => {
  if (props.isExternalLink && props.href) {
    return 'a';
  }
  return props.href ? RouterLink : 'button';
});

const buttonClasses = computed(() => [
  ...useButtonClasses({
    color: props.color,
    variant: props.variant
  }),
  'rounded-md',
  'hover:border-2',
  'focus:border-2',
  'active:border-2',
  'disabled:border disabled:hover:border disabled:active:ring-transparent',
  props.useMediaQuery
    ? {
        'h-6': props.size === 'xs',
        'h-7': props.size === 'sm',
        'h-8': props.size === 'md',
        'h-11 md:h-10': props.size === 'lg'
      }
    : {
        'h-6': props.size === 'xs',
        'h-7': props.size === 'sm',
        'h-8': props.size === 'md',
        'h-11': props.size === 'lg'
      },
  ButtonVariant.outlined === props.variant
    ? [
        'border',
        {
          'px-[15px] disabled:px-[15px] hover:px-3.5 focus:px-3.5 active:px-3.5':
            props.size === 'lg',
          'px-[13px] disabled:px-[13px] hover:px-3 focus:px-3 active:px-3': props.size === 'md',
          'px-[9px] disabled:px-[9px] hover:px-2 focus:px-2 active:px-2': props.size === 'sm',
          'px-[7px] disabled:px-[7px] hover:px-1.5 focus:px-1.5 active:px-1.5': props.size === 'xs'
        }
      ]
    : [
        'border-2',
        props.useMediaQuery
          ? {
              'px-1.5': props.size === 'xs',
              'px-2': props.size === 'sm',
              'px-3': props.size === 'md',
              'px-3.5 md:px-3': props.size === 'lg'
            }
          : {
              'px-1.5': props.size === 'xs',
              'px-2': props.size === 'sm',
              'px-3': props.size === 'md',
              'px-3.5': props.size === 'lg'
            }
      ]
]);

const iconClasses = 'h-4 w-4 opacity-70 shrink-0';
</script>

<template>
  <component
    :is="computedButtonComponent"
    :class="buttonClasses"
    :disabled="isDisabled || isLoading"
    :href="href"
    :options="hrefOptions"
    :type="type"
  >
    <component
      v-if="rawIconLeft"
      :is="rawIconLeft"
      :class="[
        iconClasses,
        {
          'animate-spin': isLoading
        }
      ]"
    />
    <div v-if="!$slots.withoutWrapper" :class="{ 'mx-auto px-1.5': rawIconLeft || rawIconRight }">
      <slot />
    </div>
    <slot name="withoutWrapper" />
    <component v-if="rawIconRight" :is="rawIconRight" :class="iconClasses" />
  </component>
</template>
