<script setup lang="ts" generic="T extends SelectableValue">
import {
  SelectContent,
  SelectIcon,
  SelectItem,
  SelectItemText,
  SelectPortal,
  SelectRoot,
  SelectTrigger,
  SelectViewport
} from 'radix-vue';

import { useZindex } from '@/hooks/useZindex';

import {
  Selectable,
  SelectableValue,
  SingleSelectBoxProps,
  useSelectBox
} from '@/components/selectBox/selectBox';

import IconButton from '@/components/button/IconButton.vue';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import SelectBoxViewport from '@/components/selectBox/SelectBoxViewport.vue';

import AngleDown from '@/icons/line/angle-down.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import SpinnerIcon from '@/icons/line/spinner.svg';
import TimesIcon from '@/icons/line/times.svg';

const props = withDefaults(defineProps<SingleSelectBoxProps<T>>(), {
  fixedHeight: true,
  placeholder: 'Choose...'
});

const emit = defineEmits<{
  'update:modelValue': [value: Selectable<T> | null];
}>();

const rightStatusIcon = computed(() => {
  if (props.isLoading) {
    return SpinnerIcon;
  }
  if (props.isSuccessful) {
    return CheckCircleIcon;
  }
});

const { nextIndex } = useZindex();
const zIndex = nextIndex();

function handleValueChange(value: string | null): void {
  const selectedValue = props.options.find((opt) => opt.value === value) ?? null;
  emit('update:modelValue', selectedValue);
}

function handleValueClear() {
  emit('update:modelValue', null);
}

const { triggerClasses } = useSelectBox<T>(props);
</script>

<template>
  <SelectRoot
    @update:modelValue="handleValueChange"
    :modelValue="modelValue?.value as string"
    :disabled="isDisabled"
  >
    <div class="relative flex w-full">
      <SelectTrigger
        v-bind="$attrs"
        :id="id"
        :class="[
          ...triggerClasses,
          'group/input truncate !pl-2.5 !pr-8',
          'enabled:hover:text-slate-900',
          isOptional && modelValue ? '!pr-16' : '!pr-8',
          modelValue ? 'text-slate-900' : 'text-slate-400',
          inputClasses
        ]"
        :tabindex="isDisabled ? -1 : 0"
      >
        <div
          class="flex flex-col truncate text-left"
          :class="{
            'text-slate-400': !modelValue,
            'gap-y-1': !fixedHeight
          }"
          :title="modelValue?.label ?? placeholder"
        >
          <slot name="trigger" :placeholder="placeholder" :modelValue="modelValue">
            <p class="truncate leading-4">{{ modelValue?.label ?? placeholder }}</p>
            <p v-if="modelValue?.description" class="truncate text-2xs leading-3 text-slate-500">
              {{ modelValue.description }}
            </p>
          </slot>
        </div>
        <div
          class="pointer-events-none absolute inset-0 left-auto flex items-center justify-center px-1"
        >
          <IconButton
            v-if="isOptional && modelValue"
            asChild
            :icon="TimesIcon"
            class="pointer-events-auto flex items-center justify-center"
            ariaLabel="Clear search"
            @click.stop="handleValueClear"
            variant="invisible"
            size="xs"
          />
          <component
            v-if="rightStatusIcon"
            :is="rightStatusIcon"
            class="group/input-disabled:opacity-50 h-4 w-4 shrink-0"
            :class="{
              'text-green-500': !isLoading && isSuccessful,
              'animate-spin text-slate-500': isLoading
            }"
          />
          <div v-else class="flex h-8 w-4.5 items-center justify-center">
            <SelectIcon asChild class="group/input-disabled:opacity-50 h-4.5 w-4.5 text-slate-500">
              <AngleDown class="h-4.5 w-4.5 shrink-0" />
            </SelectIcon>
          </div>
        </div>
      </SelectTrigger>
    </div>

    <SelectPortal>
      <SelectContent
        align="start"
        :sideOffset="4"
        position="popper"
        class="rounded-lg border border-slate-200 bg-white drop-shadow-md"
        :class="[contentWidth || 'w-[--radix-combobox-trigger-width]']"
        :style="{ zIndex }"
      >
        <SelectBoxViewport :as="SelectViewport">
          <slot name="options">
            <SelectBoxItem
              :as="SelectItem"
              v-for="option in options"
              :class="{
                'ml-4': option.header
              }"
              :key="option.label"
              :value="option.value"
              :disabled="option?.disabled ?? false"
            >
              <SelectItemText>
                <div class="font-semibold leading-5 text-slate-900">{{ option.label }}</div>
                <div v-if="option.description" class="leading-5 text-slate-500">
                  {{ option.description }}
                </div>
              </SelectItemText>
            </SelectBoxItem>
          </slot>
        </SelectBoxViewport>
      </SelectContent>
    </SelectPortal>
  </SelectRoot>
</template>
