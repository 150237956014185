<script setup lang="ts">
type AutomatedOutboundMessageData = App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageData;
type AutomatedOutboundMessageOverrideData =
  App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageOverrideData;
type UpcomingMessageData = App.Sms.Data.UpcomingMessageData;
type AutomatedOutboundMessageOverrideRecipientData =
  App.Sms.Data.AutomatedCampaigns.AutomatedOutboundMessageOverrideRecipientData;
type RecipientData = App.Sms.Data.SmsRecipientData | AutomatedOutboundMessageOverrideRecipientData;

import { AccordionRoot } from 'radix-vue';

import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import ComposeMessageInput from '@/components/sms/ComposeMessageInput.vue';
import DefinitionList from '@/components/definitionList/DefinitionList.vue';
import DefinitionListItem from '@/components/definitionList/DefinitionListItem.vue';
import FormField from '@/components/formField/FormField.vue';
import FormHelperText from '@/components/formHelperText/FormHelperText.vue';
import IconButton from '@/components/button/IconButton.vue';
import MessageBubble from '@/components/sms/MessageBubble.vue';
import Slideout from '@/components/slideout/Slideout.vue';
import SlideoutAccordionContent from '@/components/slideout/slideoutAccordion/SlideoutAccordionContent.vue';
import SlideoutAccordionHeader from '@/components/slideout/slideoutAccordion/SlideoutAccordionHeader.vue';
import SlideoutAccordionItem from '@/components/slideout/slideoutAccordion/SlideoutAccordionItem.vue';
import FormSwitchInput from '@/components/formSwitchInput/FormSwitchInput.vue';
import RecipientSelector from './RecipientSelector.vue';

import IconTimes from '@/icons/line/times.svg';
import IconStudentGroups from '@/icons/line/student-groups.svg';
import Megaphone from '@/icons/line/megaphone.svg';
import Calendar from '@/icons/line/calendar.svg';

import { isEqual } from 'lodash';

defineOptions({ inheritAttrs: false });

const props = defineProps<{
  message: AutomatedOutboundMessageData | UpcomingMessageData;
  override?: AutomatedOutboundMessageOverrideData;
  isEditing?: boolean;
}>();

const emit = defineEmits<{
  updatedOverride: [];
  createdOverride: [];
}>();

const show = defineModel<boolean>('show', {
  default: false
});

const method = props.isEditing && props.override ? 'PUT' : 'POST';

const url =
  props.isEditing && props.override
    ? route('sms.outbound-message-override.update', {
        message: props.message.id,
        override: props.override.id
      })
    : route('sms.outbound-message-override.store', { message: props.message.id });

const fields = props.isEditing
  ? {
      body: props.override?.body ?? '',
      should_skip_sending: props.override?.should_skip_sending ?? false,
      recipients: props.override?.recipients ?? []
    }
  : {
      body: '',
      should_skip_sending: false,
      recipients: []
    };

const form = useForm<{
  body: string;
  should_skip_sending: boolean;
  recipients: AutomatedOutboundMessageOverrideRecipientData[];
}>({
  url,
  method,
  fields,
  preserveScroll: true,
  preserveState: true,
  hooks: {
    success() {
      props.isEditing ? emit('updatedOverride') : emit('createdOverride');
      show.value = false;
    }
  },
  transform(data) {
    return {
      ...data,
      body: data.should_skip_sending ? '' : data.body,
      recipients: isOverridingAllStudents.value
        ? [
            {
              recipient_id: props.message.campaign?.student_group?.id,
              recipient_type: 'student_group'
            }
          ]
        : data.recipients.map((recipient) => ({
            recipient_id: recipient.recipient_id,
            recipient_type: recipient.recipient_type
          }))
    };
  }
});

const body = computed(() => (form.fields.should_skip_sending ? '' : form.fields.body));

const overrideAllStudents = ref(false);

const isOverridingAllStudents = computed(() => {
  return (
    form.fields.recipients[0]?.recipient_type === 'student_group' &&
    form.fields.recipients[0]?.recipient_id === props.message.campaign?.student_group?.id
  );
});

function handleRecipientsUpdate(recipient: RecipientData) {
  form.fields.recipients = [
    ...form.fields.recipients,
    recipient as AutomatedOutboundMessageOverrideRecipientData
  ];
}

function handleRemoveRecipient(recipient: RecipientData) {
  form.fields.recipients = form.fields.recipients.filter((existingRecipient) => {
    return !isEqual(recipient, existingRecipient);
  });
}

watch(isOverridingAllStudents, (newValue) => {
  overrideAllStudents.value = newValue;

  if (!newValue) {
    form.clearError('recipients');
  }
});

watch(overrideAllStudents, (newValue) => {
  if (newValue && props.message.campaign?.student_group) {
    form.fields.recipients = [
      {
        id: 0, // temporary ID for new recipient
        recipient_id: props.message.campaign.student_group.id,
        recipient_type: 'student_group',
        phone_number: null,
        name: props.message.campaign.student_group.name,
        student_id: null,
        parent_id: null,
        meta: null
      }
    ];
  } else {
    form.fields.recipients = [];
  }
});

onMounted(() => {
  overrideAllStudents.value = isOverridingAllStudents.value;
});
</script>

<template>
  <Slideout title="Override Campaign Message" :isOpen="show" @onClose="show = false">
    <template #content>
      <form class="flex h-full flex-col overflow-y-scroll" @submit.prevent="form.submit">
        <header class="sticky top-0 flex items-center justify-between border-b bg-white px-3 py-2">
          <h3 class="text-base font-bold text-zinc-900">Override Campaign Message</h3>
          <IconButton
            :icon="IconTimes"
            ariaLabel="Close escalation"
            variant="invisible"
            @click="show = false"
          />
        </header>

        <AccordionRoot
          type="multiple"
          :defaultValue="['message', 'recipient', 'overrides']"
          class="flex flex-grow flex-col"
        >
          <SlideoutAccordionItem value="message">
            <SlideoutAccordionHeader title="Campaign Message" />
            <SlideoutAccordionContent class="!px-0">
              <DefinitionList class="!grid-cols-definition-list px-3">
                <DefinitionListItem title="Audience" :icon="IconStudentGroups">
                  <Badge :label="message.campaign?.student_group?.name" variant="soft" size="md" />
                </DefinitionListItem>
                <DefinitionListItem title="Campaign" :icon="Megaphone">
                  <Badge :label="message.campaign?.name" variant="soft" size="md" />
                </DefinitionListItem>
                <DefinitionListItem title="Sending at" :icon="Calendar">
                  <Badge
                    :label="formatDateTime(message.scheduled_send_date)"
                    variant="soft"
                    size="md"
                  />
                </DefinitionListItem>
              </DefinitionList>
              <div class="flex flex-col items-center border-t bg-zinc-50/50 px-3 pt-2">
                <MessageBubble
                  dateTimeFormat="datetime"
                  :showDateTime="false"
                  class="mt-1 !max-w-full"
                  :message="{
                    id: 1,
                    body: message.body ?? '',
                    direction: { label: '', value: 'outbound' },
                    sent_at: message.scheduled_send_date,
                    status: { label: 'scheduled', value: 'scheduled' },
                    failure_reason: null,
                    created_at: message.created_at,
                    updated_at: message.updated_at
                  }"
                />
              </div>
            </SlideoutAccordionContent>
          </SlideoutAccordionItem>

          <SlideoutAccordionItem value="recipient">
            <SlideoutAccordionHeader title="Recipients" />
            <SlideoutAccordionContent>
              <FormHelperText>
                Choose the recipients you'd like to modify or skip this message for.
              </FormHelperText>

              <FormSwitchInput
                v-model:checked="overrideAllStudents"
                :error="
                  form.errors.recipients && isOverridingAllStudents
                    ? form.errors.recipients[0]?.recipient_id
                    : null
                "
                label="Override for all students in the campaign"
                helperText="Disabling this will require you to select a specific recipient for this message override."
                name="overrideAllStudents"
              />

              <RecipientSelector
                v-if="!overrideAllStudents"
                :recipients="form.fields.recipients"
                :errors="form.errors"
                class="-mx-3 -mb-3"
                @selectRecipient="handleRecipientsUpdate"
                @removeRecipient="handleRemoveRecipient"
              />
            </SlideoutAccordionContent>
          </SlideoutAccordionItem>
          <SlideoutAccordionItem value="overrides">
            <SlideoutAccordionHeader title="Message Overrides" />
            <SlideoutAccordionContent>
              <FormHelperText>
                You may choose to skip sending this message or provide a custom message to be sent.
              </FormHelperText>
              <FormSwitchInput
                v-model:checked="form.fields.should_skip_sending"
                label="Skip sending this message"
                name="should_skip_sending"
                :error="form.errors.should_skip_sending"
              />

              <FormField label="Custom Message">
                <ComposeMessageInput
                  variant="campaign"
                  :hasError="!!form.errors.body"
                  :error="form.errors.body"
                  :isDisabled="form.fields.should_skip_sending"
                  :modelValue="body"
                  @update:model-value="form.fields.body = $event"
                  :canUploadAttachments="false"
                  :canScheduleMessage="false"
                />
              </FormField>
            </SlideoutAccordionContent>
          </SlideoutAccordionItem>
        </AccordionRoot>

        <div class="sticky bottom-0 flex items-center space-x-2 border-t bg-white px-3 py-2">
          <Button
            :color="ButtonColor.slate"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.outlined"
            class="w-full"
            useMediaQuery
            @click="show = false"
          >
            Cancel
          </Button>
          <Button
            :color="ButtonColor.primary"
            :isDisabled="form.processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            class="w-full"
            type="submit"
            useMediaQuery
          >
            Save
          </Button>
        </div>
      </form>
    </template>
  </Slideout>
</template>
