<script setup lang="ts">
import { usePageMeta } from '@/hooks/usePageMeta';

usePageMeta();

withDefaults(
  defineProps<{
    width?: 'sm' | 'lg';
  }>(),
  {
    width: 'sm'
  }
);
</script>

<template>
  <main class="relative flex h-screen w-full overflow-hidden">
    <div
      class="relative flex h-full w-full flex-col items-center justify-start overflow-y-auto md:h-auto lg:w-1/2"
    >
      <div
        class="md:px-30 flex w-full flex-col px-7 pb-7 pt-10 xs:pt-14 md:my-auto md:pt-16 2xl:pt-24 3xl:pt-32"
        :class="{
          'max-w-108': width === 'sm',
          'max-w-142': width === 'lg'
        }"
      >
        <slot />
      </div>
    </div>
    <div class="bg hidden h-screen w-1/2 lg:block"></div>
  </main>
</template>

<style scoped>
.bg {
  background-image: url('@/images/backgrounds/login-bg.jpg');
  background-size: cover;
  background-position: right 67% bottom 0;
}
</style>
