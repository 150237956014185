<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';
import { useFormHelpers } from '@/hooks/useFormHelpers';

import Alert from '@/components/alert/Alert.vue';
import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import IconArrowLeft from '@/icons/line/arrow-left.svg';
import IconButton from '@/components/button/IconButton.vue';
import IconLock from '@/icons/line/lock.svg';
import IconTimesCircle from '@/icons/line/times-circle.svg';

const props = defineProps<{
  email: string;
}>();

const loginForm = useForm({
  method: 'POST',
  url: route('login.password.show'),
  fields: {
    password: ''
  }
});

const { isClean } = useFormHelpers(loginForm);

const forgotPasswordForm = useForm({
  method: 'POST',
  url: route('password.email'),
  fields: {
    email: props.email
  }
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    :description="`You are logging in as ${email}`"
    textAlignment="center"
    title="Enter password"
  >
    <template #description>
      You are logging in as <strong class="font-semibold">{{ email }}</strong>
    </template>
    <template #header>
      <div class="flex items-center justify-center">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <IconLock class="mx-auto h-12 w-12 text-slate-500" />
        </div>
      </div>
    </template>
    <IconButton
      :color="ButtonColor.slate"
      :href="route('login.show')"
      :icon="IconArrowLeft"
      :size="ButtonSize.lg"
      :variant="ButtonVariant.invisible"
      ariaLabel="`Back to login`"
      class="absolute left-5 top-5 xs:left-6 xs:top-6 sm:left-10 sm:top-10 2xl:left-16 2xl:top-16"
      useMediaQuery
    />

    <AuthForm @submit.prevent="loginForm.submit">
      <FormRows>
        <FormTextInput
          label="Password"
          name="password"
          type="password"
          placeholder="Password"
          :modelValue="loginForm.fields.password"
          :error="loginForm.errors.password"
          @update:modelValue="(value: any) => (loginForm.fields.password = value)"
        />
      </FormRows>
      <Alert
        v-if="loginForm.hasErrors"
        :icon="IconTimesCircle"
        variant="soft"
        color="danger"
        title="The credentials are incorrect"
        description="If you've forgotten your password we can help you recover it. Please click the Forgot Password button below."
      />
      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.slate"
            :isDisabled="forgotPasswordForm.processing"
            :isLoading="forgotPasswordForm.processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            useMediaQuery
            @click="forgotPasswordForm.submit"
            >Forgot password</Button
          >
          <Button
            :color="ButtonColor.primary"
            :isDisabled="loginForm.processing || isClean"
            :isLoading="loginForm.processing"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            useMediaQuery
            type="submit"
            >Login</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
