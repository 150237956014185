<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import PasswordStrength from '@/components/passwordStrength/PasswordStrength.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import KoDotContainer from '@/icons/line/ko-dot-container.svg';
import Check from '@/icons/line/check.svg';
import Lock from '@/icons/line/lock.svg';

import Logo from '@/images/cac-logo.svg';
import AbstractTriangle from '@/images/abstract-triangle.svg';

const form = useForm<{
  password: string;
  new_password: string;
  new_password_confirmation: string;
}>({
  method: 'PUT',
  url: route('account.password.update'),
  fields: {
    password: '',
    new_password: '',
    new_password_confirmation: ''
  }
});

const isPasswordLongEnough = computed(() =>
  form.fields.new_password ? form.fields.new_password.length >= 8 : false
);
const doPasswordsMatch = computed(
  () =>
    form.fields.new_password &&
    form.fields.new_password.length > 0 &&
    form.fields.new_password === form.fields.new_password_confirmation
);
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Password Update Required"
    description="Your password has expired. To continue, please update your password. Please note that passwords expire every 90 days."
  >
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <Logo class="mx-auto h-10" />
        </div>
        <div class="w-[73px]">
          <AbstractTriangle />
        </div>
      </div>
    </template>
    <AuthForm @submit.prevent="form.submit">
      <FormRows>
        <FormTextInput
          :error="form.errors.password"
          :iconLeft="Lock"
          :modelValue="form.fields.password"
          label="Current password"
          name="password"
          type="password"
          @update:modelValue="(value) => (form.fields.password = value)"
        />
        <FormRows>
          <FormTextInput
            :error="form.errors.new_password"
            :iconLeft="Lock"
            :modelValue="form.fields.new_password"
            label="New password"
            name="new_password"
            type="password"
            @update:modelValue="(value) => (form.fields.new_password = value)"
          >
            <template #helperText>
              <PasswordStrength :password="form.fields.new_password" />
            </template>
          </FormTextInput>
          <FormTextInput
            :error="form.errors.new_password_confirmation"
            :iconLeft="Lock"
            :modelValue="form.fields.new_password_confirmation"
            label="Confirm new password"
            name="new_password_confirmation"
            type="password"
            @update:modelValue="(value) => (form.fields.new_password_confirmation = value)"
          />
        </FormRows>
        <ul class="flex flex-col gap-1.5 text-sm text-slate-700">
          <li class="flex items-center gap-2">
            <Check class="w-4 text-green-600" v-if="isPasswordLongEnough" />
            <KoDotContainer v-else />
            Password should be at least 8 characters long
          </li>
          <li class="flex items-center gap-2">
            <Check class="w-4 text-green-600" v-if="doPasswordsMatch" />
            <KoDotContainer v-else />
            Confirm your password
          </li>
        </ul>
      </FormRows>

      <template #footer>
        <StackedFormFooter>
          <Button
            type="submit"
            :size="ButtonSize.lg"
            :color="ButtonColor.primary"
            :isDisabled="form.processing"
            :isLoading="form.processing"
            :variant="ButtonVariant.solid"
            useMediaQuery
            >Continue</Button
          >
          <Button
            :color="ButtonColor.slate"
            :href="route('logout.store')"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            useMediaQuery
            >Back to login</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
