<script setup lang="ts">
import BottomNavigation from '@/components/bottomNavigation/BottomNavigation.vue';
import BottomNavigationItem from '@/components/bottomNavigation/BottomNavigationItem.vue';

import HomeSolid from '@/icons/solid/home.svg';
import HomeLine from '@/icons/line/home.svg';
import CheckCircleSolid from '@/icons/solid/check-circle.svg';
import CheckCircleLine from '@/icons/line/check-circle.svg';
import CommentsSolid from '@/icons/solid/comments.svg';
import CommentsLine from '@/icons/line/comments.svg';
import UserSolid from '@/icons/solid/user.svg';
import UserLine from '@/icons/line/user.svg';
import StudentParentHeaderBg from '@/images/backgrounds/student-parent-header-bg.svg';
import { ref, onMounted, onUnmounted, computed } from 'vue';

const headerOffset = ref(0);
const containerRef = ref<HTMLElement | null>(null);

const isHeaderOffscreen = computed(() => {
  return headerOffset.value <= -206;
});

onMounted(() => {
  containerRef.value?.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  containerRef.value?.removeEventListener('scroll', handleScroll);
});

const handleScroll = (event: Event) => {
  const container = event.target as HTMLElement;
  const scrollTop = container.scrollTop;
  headerOffset.value = -scrollTop * 0.5;
};
</script>

<template>
  <div
    ref="containerRef"
    class="relative h-screen overflow-auto bg-slate-50 pb-[51px] tall:pb-[67px]"
  >
    <div
      class="fixed inset-x-0 h-[206px] bg-secondary-900"
      :style="{ transform: `translateY(${headerOffset}px)` }"
      :class="{ 'pointer-events-none': isHeaderOffscreen }"
    >
      <StudentParentHeaderBg class="absolute right-4.5 top-3.5 h-4/5 tall:h-full xs:right-5.5" />
    </div>
    <header class="relative z-20 overflow-hidden px-4.5 pt-4 xs:px-5.5">
      <div class="relative z-10 mx-auto flex max-w-screen-sm flex-col gap-y-5 pb-5">
        <h1 class="text-xl font-bold leading-7 text-white">Home</h1>
        <div
          class="fixed inset-x-0 top-0 bg-secondary-900 px-6 py-4 text-center transition-transform duration-300"
          aria-hidden="true"
          :class="{
            'hidden translate-y-0 tall:block': isHeaderOffscreen,
            '-translate-y-full': !isHeaderOffscreen
          }"
        >
          <span class="text-base font-bold leading-5 text-white">Home</span>
        </div>
        <div class="flex gap-x-3">
          <div class="basis-1/2 rounded-lg bg-secondary-950 px-4 py-3.5 text-white">
            <span class="text-3xl font-medium leading-8">80%</span>
            <p class="text-sm font-medium leading-5">tasks complete</p>
          </div>
          <div class="basis-1/2 rounded-lg bg-secondary-700/80 px-4 py-3.5 text-white">
            <span class="text-3xl font-medium leading-8">2</span>
            <p class="text-sm font-medium leading-5">tasks remaining</p>
          </div>
        </div>
      </div>
      <div
        class="fixed bottom-0 left-0 right-0 z-10 border-t border-slate-200 bg-white px-4.5 xs:px-5.5"
      >
        <div class="mx-auto max-w-screen-sm">
          <BottomNavigation :activeIndex="0">
            <BottomNavigationItem
              :icon="HomeLine"
              :iconActive="HomeSolid"
              href="#"
              isActive
              label="Home"
            />
            <BottomNavigationItem
              :badgeCount="2"
              :icon="CheckCircleLine"
              :iconActive="CheckCircleSolid"
              href="#"
              label="Tasks"
            />
            <BottomNavigationItem
              :badgeCount="1"
              :icon="CommentsLine"
              :iconActive="CommentsSolid"
              href="#"
              label="Chat"
            />
            <BottomNavigationItem
              :icon="UserLine"
              :iconActive="UserSolid"
              href="#"
              label="Profile"
            />
          </BottomNavigation>
        </div>
      </div>
    </header>
    <main class="relative z-10 px-4.5 pb-6 xs:px-5.5">
      <div class="mx-auto max-w-screen-sm">
        <slot />
      </div>
    </main>
  </div>
</template>
