<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import IconButton from '@/components/button/IconButton.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import IconHash from '@/icons/line/hash.svg';
import IconArrowLeft from '@/icons/line/arrow-left.svg';

const codeLength = 6;

const props = defineProps<{
  codeSentToChannel?: App.Auth.Data.TwoFactorChannelData;
  channels: Array<App.Auth.Data.TwoFactorChannelData>;
}>();

const selectedChannel = ref(props.codeSentToChannel || null);

const sendCode = useForm({
  method: 'POST',
  fields: {}
});

const verifyCode = useForm({
  method: 'POST',
  fields: {
    code: ''
  }
});

const codeEntered = computed(() => verifyCode.fields.code.length === codeLength);
const userHasMultipleChannels = computed(() => props.channels.length > 1);

function handleSubmit() {
  verifyCode.submitWith({
    url: route('two-factor.login.store', {
      channel: selectedChannel.value!.id
    })
  });
}

function handleResendCode() {
  if (selectedChannel?.value?.id) {
    sendCode.submitWith({
      url: route('two-factor.deliver-code.store', {
        channel: selectedChannel.value.id
      })
    });
  }
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    textAlignment="center"
    title="Enter verification code"
    :description="`A text message with a ${codeLength} digit code has been sent to ${selectedChannel?.redactedDestination}. This helps us to keep your account secure by verifying it’s really you.`"
  >
    <template #header>
      <div class="flex items-center justify-center">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <IconHash class="mx-auto h-12 w-12 text-slate-500" />
        </div>
      </div>
    </template>
    <IconButton
      :color="ButtonColor.slate"
      :href="route('login.show')"
      :icon="IconArrowLeft"
      :size="ButtonSize.lg"
      :variant="ButtonVariant.invisible"
      ariaLabel="`Back to login`"
      class="absolute left-5 top-5 xs:left-6 xs:top-6 sm:left-10 sm:top-10 2xl:left-16 2xl:top-16"
      useMediaQuery
    />
    <AuthForm @submit.prevent="handleSubmit">
      <FormTextInput
        label="Enter Verification Code"
        placeholder="------"
        name="code"
        type="text"
        :value="verifyCode.fields.code"
        :error="verifyCode.errors.code"
        @update:model-value="(value) => (verifyCode.fields.code = value as string)"
      />

      <template #footer>
        <StackedFormFooter>
          <Button
            type="submit"
            :color="ButtonColor.primary"
            :isDisabled="!codeEntered"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.solid"
            :isLoading="verifyCode.processing"
            useMediaQuery
            >Submit</Button
          >
          <Button
            :color="ButtonColor.slate"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.soft"
            type="button"
            :isLoading="sendCode.processing"
            useMediaQuery
            @click="handleResendCode"
            >Resend Code</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>

    <StackedFormFooter class="mt-8">
      <Button
        v-if="userHasMultipleChannels"
        :color="ButtonColor.slate"
        :href="route('two-factor.select-channel.show')"
        :size="ButtonSize.lg"
        :variant="ButtonVariant.invisible"
        useMediaQuery
      >
        Use another verification method
      </Button>
      <Button
        :color="ButtonColor.slate"
        :size="ButtonSize.lg"
        :variant="ButtonVariant.invisible"
        useMediaQuery
        @click="router.post(route('logout.store'))"
      >
        Sign out
      </Button>
    </StackedFormFooter>
  </AuthFormWrapper>
</template>
