import { CssClass } from '@/@types/global';
import { ComboboxTrigger } from 'radix-vue';
import { Component, ref } from 'vue';
import ComboboxSearchInput from '@/components/selectBox/combobox/ComboboxSearchInput.vue';

export type DualListboxOption = {
  label: string;
  value: string;
  children?: DualListboxOption[];
};

export type SelectableValue = string | number | boolean | null;

export interface Selectable<
  T extends SelectableValue,
  M extends Record<string, unknown> = Record<string, unknown>
> {
  label: string;
  description?: string | null;
  header?: string | null;
  value: T;
  disabled?: boolean;
  meta?: M;
}

interface BaseSelectBoxProps<T extends SelectableValue> {
  options: Selectable<T>[];
  id?: string;
  hasError?: boolean;
  isLoading?: boolean;
  isSuccessful?: boolean;
  isDisabled?: boolean;
  isReadonly?: boolean;
  isOptional?: boolean;
  placeholder?: string;
  fixedHeight?: boolean;
  onFilter?: (query: string) => void;
  searchFilters?: Record<string, unknown>;
  iconLeft?: Component;
  inputClasses?: CssClass;
  triggerClasses?: CssClass;
  contentWidth?: CssClass;
  showQueryAsOption?: boolean;
}

export interface MultiSelectBoxProps<T extends SelectableValue> extends BaseSelectBoxProps<T> {
  modelValue: Selectable<T>[];
  selectAll?: boolean;
  selectAllLabel?: string;
  searchable?: boolean;
  /**
   * In this case where all records are selected, do we want to view as a distinct options or as a single option?
   * i.e. distinct - "Georgia", "Washington", "South Dakota", +47
   *      single - "All states"
   */
  selectAllViewAs?: 'distinct' | 'single';
}

export interface SingleSelectBoxProps<T extends SelectableValue> extends BaseSelectBoxProps<T> {
  modelValue?: Selectable<T> | null;
}

export function filter<T extends SelectableValue>(list: Selectable<T>[], query: string) {
  const lowerCaseQuery = query.toLowerCase();

  return list.filter(({ label }) => label.toLowerCase().includes(lowerCaseQuery));
}

export type SelectBoxProps<T extends SelectableValue> =
  | MultiSelectBoxProps<T>
  | SingleSelectBoxProps<T>;

export function useSelectBox<T extends SelectableValue>(props: BaseSelectBoxProps<T>) {
  const isTouchPointer = ref<boolean>(false);
  const searchQuery = ref<string | undefined>();
  const triggerElement = ref<InstanceType<typeof ComboboxTrigger> | null>(null);
  const searchInputElement = ref<InstanceType<typeof ComboboxSearchInput> | null>(null);

  function getFilteredOptions() {
    if (props.onFilter || !searchQuery.value) {
      return props.options;
    }

    return filter(props.options, searchQuery.value);
  }

  const filteredOptions = computed(() => {
    const options = getFilteredOptions();

    if (
      props.showQueryAsOption &&
      searchQuery.value &&
      !options.find((option) => option.label === searchQuery.value)
    ) {
      return [
        ...options,
        {
          value: searchQuery.value as T,
          label: searchQuery.value as string
        }
      ];
    }

    return options;
  });

  function focusOnTrigger(): void {
    requestAnimationFrame(() => {
      triggerElement?.value?.$el.focus();
    });
  }

  function focusOnInput(): void {
    if (!isTouchPointer.value) {
      searchInputElement?.value?.searchInputElement?.$el.focus();
    }
  }

  const triggerClasses = computed(() => [
    'placeholder-slate-400',
    'flex w-full items-center text-sm min-h-10 px-[3px] py-2 md:min-h-8 md:px-[3px] md:py-[3px]',
    'rounded-lg border bg-white !outline-none',
    'focus:ring-3',
    'focus-within:ring-3',
    'data-[state=open]:ring-3',
    'disabled:cursor-not-allowed data-[disabled=true]:cursor-not-allowed',
    {
      'h-10 md:h-8': props.fixedHeight
    },
    !props.isReadonly && ['disabled:opacity-60 data-[disabled=true]:opacity-60'],
    props.isReadonly && ['disabled:opacity-100'],
    props.hasError
      ? [
          'border-red-500',
          'enabled:hover:border-red-700',
          'enabled:focus:border-red-500 enabled:focus:ring-red-600/10 enabled:focus-within:border-red-600/10',
          'data-[state=open]:border-red-500 data-[state=open]:ring-slate-600/10'
        ]
      : [
          'border-slate-200',
          'enabled:hover:border-slate-300',
          'enabled:focus:border-slate-400 enabled:focus:ring-slate-700/40 enabled:focus-within:border-slate-400 enabled:focus:ring-slate-700/40',
          'data-[state=open]:border-slate-400 data-[state=open]:ring-slate-700/40'
        ]
  ]);

  const hasNoResultsForQuery = computed<boolean>(() => {
    return filteredOptions.value.length === 0;
  });

  function handleSearchQuery(query: string): void {
    searchQuery.value = query;

    if (props.onFilter) {
      props.onFilter(query);
    }
  }

  return {
    filteredOptions,
    focusOnInput,
    focusOnTrigger,
    handleSearchQuery,
    hasNoResultsForQuery,
    searchInputElement,
    searchQuery,
    triggerClasses,
    triggerElement,
    isTouchPointer,
    triggerEvents: {
      touchstart: () => (isTouchPointer.value = true),
      pointerdown: () => (isTouchPointer.value = false)
    }
  };
}
