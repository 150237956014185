<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';
import FormCheckboxInput from '@/components/formCheckboxInput/FormCheckboxInput.vue';
import FormRows from '@/components/formRows/FormRows.vue';
import FormTextInput from '@/components/formTextInput/FormTextInput.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import Logo from '@/images/cac-logo.svg';
import AbstractTriangle from '@/images/abstract-triangle.svg';

const props = defineProps<{
  contents: string;
  document: string;
}>();

defineOptions({
  properties: {
    width: 'lg'
  }
});

const form = useForm<{
  checked: boolean;
  name: string;
}>({
  method: 'POST',
  url: route('document-acknowledgement.store', { document: props.document }),
  fields: {
    checked: false,
    name: ''
  },
  transform: (data) => ({
    ...data,
    document: props.document
  })
});
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Document Acknowledgement"
    description="As an adviser with College Advising Corps and a user of our data management platform, GRACE, you are entering and handling student data that are protected by federal law and data security policies. Before accessing GRACE and student data, you must acknowledge and agree to College Advising Corps’ Confidentiality and Data Use Policy."
  >
    <template #header>
      <div class="flex items-center justify-between">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <Logo class="mx-auto h-10" />
        </div>
        <div class="w-[73px]">
          <AbstractTriangle />
        </div>
      </div>
    </template>
    <AuthForm @submit.prevent="form.submit">
      <FormRows>
        <div
          class="prose rich-content-html max-w-full rounded-lg border border-slate-200 px-4 py-2 text-[16px]"
          v-html="contents"
        />
        <FormCheckboxInput
          :checked="form.fields.checked"
          :error="form.errors.checked"
          label="I have read and understand the document."
          name="checked"
          @update:checked="(value) => (form.fields.checked = value)"
        />
        <FormTextInput
          :error="form.errors.name"
          :modelValue="form.fields.name"
          helperText="Typing your name will act as an e-signature for this agreement."
          label="Name"
          name="name"
          @update:modelValue="(value) => (form.fields.name = value)"
        />
      </FormRows>
      <template #footer>
        <StackedFormFooter>
          <Button
            type="submit"
            :size="ButtonSize.lg"
            :color="ButtonColor.primary"
            :isDisabled="!form.fields.checked"
            :isLoading="form.processing"
            :variant="ButtonVariant.solid"
            useMediaQuery
            >I acknowledge the document</Button
          >
          <Button
            :color="ButtonColor.slate"
            :href="route('logout.store')"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            useMediaQuery
            >Back to login</Button
          >
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
