<script setup lang="ts">
import { toRaw } from 'vue';

import { ButtonColor } from '@/hooks/useButtonClasses';
import { useZindex } from '@/hooks/useZindex';
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component';
import Button from '@/components/button/Button.vue';

export type DialogProps = {
  cancelButtonLabel?: string | null;
  confirmButtonDisabled?: boolean;
  confirmButtonLabel?: string;
  confirmButtonColor?: ButtonColor | keyof typeof ButtonColor;
  contentClass?: string;
  description?: string;
  formId?: string;
  footerHelperText?: string;
  icon?: Component;
  isLoading?: boolean;
  isOpen?: boolean;
  message?: string;
  title: string;
  variant?: 'default' | 'danger' | 'warning';
};

const props = withDefaults(defineProps<DialogProps>(), {
  cancelButtonLabel: 'Cancel',
  confirmButtonLabel: 'Save changes',
  isOpen: false,
  variant: 'default',
  contentClass: 'max-w-[475px]'
});

const emit = defineEmits<{
  onCancel: [void];
  onClose: [void];
  onConfirm: [void];
}>();

const rawIcon = toRaw(props.icon);

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const computedButtonColor = computed(() => {
  const colorMap: Record<string, 'danger' | 'primary' | 'slate'> = {
    default: 'primary',
    danger: 'danger',
    warning: 'slate'
  };
  return props.confirmButtonColor || colorMap[props.variant] || undefined;
});

function onCancelButtonClick() {
  emit('onCancel');
}

function onOverlayClick() {
  emit('onClose');
}

function onSubmit() {
  emit('onConfirm');
}
</script>

<template>
  <teleport to="body">
    <Transition
      ariaLive="assertive"
      enterActiveClass="transitionSlideUpAndFade duration-200"
      leaveActiveClass="transition-slide-up-and-fade duration-200 absolute inset-x-0 top-0"
      enterFromClass="opacity-50"
      leaveToClass="opacity-0"
      class="fixed"
      :style="{ zIndex }"
    >
      <UseFocusTrap v-if="isOpen">
        <form @submit.prevent="onSubmit" class="px-4">
          <div
            class="fixed inset-0 bg-black/70 data-[state=open]:animate-overlay-show"
            @click="onOverlayClick"
            :style="{ zIndex }"
          />

          <div
            class="pointer-events-none fixed left-[50%] top-[50%] flex w-full translate-x-[-50%] translate-y-[-50%] flex-col items-center px-4"
            :style="{ zIndex }"
          >
            <div
              class="pointer-events-auto flex max-h-[85vh] w-full flex-col rounded-lg border border-slate-200 bg-white shadow-xl focus:outline-none data-[state=open]:animate-content-show"
              :class="contentClass"
            >
              <div class="flex flex-col gap-1 border-b border-slate-200 p-5 pb-2 leading-5">
                <slot name="header">
                  <div class="text-base font-semibold text-slate-900">
                    {{ title }}
                  </div>
                  <div v-if="description" class="text-sm text-slate-500">
                    {{ description }}
                  </div>
                </slot>
              </div>
              <div class="flex flex-col gap-y-4 overflow-auto px-5 py-6">
                <div class="flex items-start gap-2">
                  <div
                    v-if="rawIcon"
                    class="rounded-full p-1"
                    :class="{
                      'bg-slate-100': variant === 'default',
                      'bg-red-100': variant === 'danger',
                      'bg-amber-100': variant === 'warning'
                    }"
                  >
                    <component
                      :is="rawIcon"
                      class="h-4.5 w-4.5"
                      :class="{
                        'text-slate-500': variant === 'default',
                        'text-red-500': variant === 'danger',
                        'text-amber-700': variant === 'warning'
                      }"
                    />
                  </div>
                  <slot>
                    <div class="whitespace-pre-line text-sm leading-5 text-slate-500">
                      <slot name="message">{{ message }}</slot>
                    </div>
                  </slot>
                </div>
                <div v-if="$slots.body">
                  <slot name="body" />
                </div>
              </div>
              <div
                class="flex w-full items-center justify-between gap-x-6 border-t border-slate-200 px-5 py-2.5"
              >
                <slot name="footerHelperText">
                  <p class="text-sm font-semibold text-slate-700">{{ footerHelperText }}</p>
                </slot>
                <div class="ml-auto flex justify-end gap-2">
                  <slot name="footer">
                    <Button
                      v-if="cancelButtonLabel"
                      :color="ButtonColor.slate"
                      size="sm"
                      variant="outlined"
                      @click="onCancelButtonClick"
                    >
                      {{ cancelButtonLabel }}
                    </Button>
                    <Button
                      v-if="confirmButtonLabel"
                      :color="computedButtonColor"
                      :isDisabled="isLoading || confirmButtonDisabled"
                      :isLoading="isLoading"
                      size="sm"
                      type="submit"
                      variant="solid"
                    >
                      {{ confirmButtonLabel }}
                    </Button>
                  </slot>
                </div>
              </div>
            </div>
          </div>
        </form>
      </UseFocusTrap>
    </Transition>
  </teleport>
</template>
