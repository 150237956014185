<script setup lang="ts">
import SpinnerIcon from '@/icons/line/spinner.svg';
import CheckCircleIcon from '@/icons/line/check-circle.svg';
import type { TextInputProps, TextInputEmits } from './TextInputProps';

const props = withDefaults(defineProps<TextInputProps>(), {
  type: 'text',
  variant: 'default',
  autocomplete: 'off'
});

const emit = defineEmits<TextInputEmits>();

const rawIconLeft = toRaw(props.iconLeft);
const rawIconRight = toRaw(props.iconRight);

const showPassword = ref(false);

const inputEl = ref<HTMLInputElement | null>(null);
defineExpose({ inputEl });

onMounted(() => {
  if (props.autofocus && inputEl.value) {
    inputEl.value.focus();
  }
});

const rightIconComponent = computed(() => {
  if (props.isLoading) {
    return SpinnerIcon;
  }
  if (props.isSuccessful) {
    return CheckCircleIcon;
  }
  return rawIconRight;
});

function togglePasswordVisibility() {
  if (props.type === 'password') {
    showPassword.value = !showPassword.value;
  }
}

function onValueChange(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement).value ?? '');
}
</script>

<template>
  <div class="relative z-0 flex items-center" :class="{ 'opacity-50': isDisabled }">
    <div
      v-if="rawIconLeft"
      class="absolute right-auto z-10 ml-3 h-4.5 w-4.5 opacity-70"
      :class="hasError && variant === 'filled' ? 'text-red-400' : 'text-slate-500'"
    >
      <component :is="rawIconLeft" />
    </div>
    <div class="relative flex h-full grow items-center">
      <div
        v-if="prepend"
        class="flex h-8 items-center rounded-l-lg border border-slate-200 border-r-transparent bg-slate-50 py-1.5 pl-3 pr-2.5 text-sm text-slate-500"
      >
        {{ prepend }}
      </div>
      <div class="relative flex h-full grow items-center">
        <input
          ref="inputEl"
          class="h-10 w-full px-3 py-2.5 text-[16px] text-slate-900 transition-input duration-150 ease-in-out focus:ring-3 disabled:cursor-not-allowed disabled:opacity-60 md:h-8 md:py-1.5 md:text-sm"
          :class="[
            inputClasses,
            {
              'rounded-lg': !prepend && !append,
              'rounded-r-lg': prepend && !append,
              'rounded-l-lg': !prepend && append,
              'cursor-not-allowed': isDisabled,
              'cursor-text': !isDisabled,
              'pl-10.5': rawIconLeft,
              'pr-10.5': rawIconRight && type !== 'password',
              'pr-13': type === 'password' && !rawIconRight,
              'pr-23': type === 'password' && rawIconRight,
              'border-slate-200 bg-white focus:border-slate-700 focus:ring-slate-700/40 enabled:hover:border-slate-700':
                variant === 'default' && !hasError,
              'border-transparent bg-slate-100 focus:border-transparent focus:bg-slate-200 focus:ring-slate-300/50 enabled:hover:bg-slate-200':
                variant === 'filled' && !hasError,
              'border-red-500 bg-white focus:border-red-500 focus:ring-red-500/40':
                variant === 'default' && hasError,
              'border-transparent bg-red-100 focus:border-transparent focus:bg-red-200 focus:ring-red-600/10':
                variant === 'filled' && hasError
            }
          ]"
          :disabled="isDisabled"
          :readonly="isReadonly"
          :id="id"
          :max="max"
          :min="min"
          :name="name"
          :placeholder="placeholder"
          :step="step"
          :type="type === 'password' ? (showPassword ? 'text' : 'password') : type"
          :value="modelValue"
          @input="onValueChange"
          @blur="$emit('blur')"
          @focus="$emit('focus')"
          :autofocus="autofocus"
          :autocomplete="autocomplete"
        />
        <button
          v-if="type === 'password'"
          type="button"
          class="absolute text-sm text-slate-900"
          :class="{
            'right-3': !rawIconRight,
            'right-10': rawIconRight
          }"
          @click="togglePasswordVisibility"
        >
          {{ showPassword ? 'Hide' : 'Show' }}
        </button>
      </div>
      <div
        v-if="append"
        class="flex h-8 items-center rounded-r-lg border border-slate-200 border-l-transparent bg-slate-50 py-1.5 pl-3 pr-2.5 text-sm text-slate-500"
      >
        {{ append }}
      </div>
    </div>
    <div
      v-if="rightIconComponent"
      class="absolute right-0 z-10 mr-3 h-4.5 w-4.5 opacity-70"
      :class="[
        {
          'text-red-400': hasError,
          'text-green-500': isSuccessful,
          'text-slate-500': !hasError && !isSuccessful,
          'animate-spin': isLoading
        }
      ]"
    >
      <component :is="rightIconComponent" />
    </div>
  </div>
</template>
