<script setup lang="ts" generic="T extends SelectableValue">
import type { Ref } from 'vue';
import {
  ComboboxAnchor,
  ComboboxContent,
  ComboboxItem,
  ComboboxPortal,
  ComboboxRoot,
  ComboboxTrigger,
  ComboboxViewport
} from 'radix-vue';
import { vIntersectionObserver } from '@vueuse/components';

import { ButtonSize } from '@/hooks/useButtonClasses';

import {
  MultiSelectBoxProps,
  Selectable,
  SelectableValue,
  useSelectBox
} from '@/components/selectBox/selectBox';
import Badge from '@/components/badge/Badge.vue';
import NoResults from '@/components/selectBox/combobox/NoResults.vue';
import NoOptions from '@/components/selectBox/combobox/NoOptions.vue';
import SelectBoxViewport from '@/components/selectBox/SelectBoxViewport.vue';
import SelectBoxItem from '@/components/selectBox/SelectBoxItem.vue';
import ComboboxSearchInput from './ComboboxSearchInput.vue';
import Checkbox from './Checkbox.vue';
import { useZindex } from '@/hooks/useZindex';

import AngleDown from '@/icons/line/angle-down.svg';
import BringBottom from '@/icons/line/bring-bottom.svg';
import BringFront from '@/icons/line/bring-front.svg';
import LockIcon from '@/icons/line/lock.svg';
import IconButton from '@/components/button/IconButton.vue';

const props = withDefaults(defineProps<MultiSelectBoxProps<T>>(), {
  placeholder: 'Choose...',
  selectAll: false,
  selectAllLabel: 'Select all',
  searchable: true
});

const emit = defineEmits<{
  'update:modelValue': [Selectable<T>[]];
  open: [];
}>();

defineSlots<{
  option?: (props: { option: Selectable<T>; checked?: boolean; close: () => void }) => unknown;
}>();

const { nextIndex } = useZindex();
const zIndex = nextIndex();

const isOpen = ref(false);
const overflowBoundaryElement = ref(null);
const isTruncatingBadges = ref(true);
/**
 * We can't use the ref generic here because vue will change the type
 * of `value` to UnwrapRef<T> which will break the type of the `value`
 * property on the Selectable interface when we push to the array.
 * @see https://github.com/vuejs/composition-api/issues/432#issuecomment-655326830
 */
const overflowingSelections = ref([]) as Ref<Selectable<T>[]>;

/**
 * Derive the selected values into a set to speed up checks on selected values.
 * This directly helps with "Select All" checks.
 */
const modelValueSet = computed(() =>
  props.modelValue.reduce((acc, curr) => {
    acc.add(curr.value);
    return acc;
  }, new Set<T>())
);
const areAllSelected = computed(() => props.options.every((o) => modelValueSet.value.has(o.value)));

/**
 * Selectables that are overflowing the bounds of the list
 * ordered to match the selection order.
 */
const orderedOverflowingSelections = computed<Selectable<T>[]>(() => {
  return (
    props.modelValue?.filter((selectedOption) => {
      return overflowingSelections.value.some((option) => option.value === selectedOption.value);
    }) || []
  );
});
/**
 * When a Selectable is removed, ensure it is removed
 * from the overflowingSelections array.
 */
watch(
  () => props.modelValue,
  () => {
    overflowingSelections.value = overflowingSelections.value.filter(
      (option) => props.modelValue?.some((selectedOption) => selectedOption.value === option.value)
    );
  }
);

const {
  filteredOptions,
  focusOnInput,
  focusOnTrigger,
  handleSearchQuery,
  hasNoResultsForQuery,
  searchInputElement,
  searchQuery,
  triggerClasses,
  triggerElement,
  triggerEvents
} = useSelectBox<T>(props);

function onBadgeIntersection(
  option: Selectable<T>,
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[]
) {
  if (isSelected(option) && !isIntersecting) {
    overflowingSelections.value.push(option);
  } else {
    // if the current badge should be visible then all preceding indices should be visible as well
    const toRemove = props.modelValue
      .slice(0, index)
      .map((selectedOption) => selectedOption.value)
      .concat(option.value);
    overflowingSelections.value = overflowingSelections.value.filter(
      (selectedOption) => !toRemove.includes(selectedOption.value)
    );
  }
}

/**
 * Handle the edge case where the overflow badge is only partially visible.
 * In this case, we should also add the prev option to overflow.
 */
function onFirstBadgeIntersection(
  option: Selectable<T>,
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[]
) {
  if (isFirstInvisibleBadge(option) && !isIntersecting && index > 0) {
    const prevIndex = index - 1;
    overflowingSelections.value.push(props.modelValue[prevIndex]);
  }
}

function isBadgeOverflowing(option: Selectable<T>) {
  return (
    isTruncatingBadges.value &&
    orderedOverflowingSelections.value.some(
      (selectedOption) => selectedOption.value === option.value
    )
  );
}

function isFirstInvisibleBadge(option: Selectable<T>) {
  return orderedOverflowingSelections?.value[0]?.value === option.value;
}

function isSelected(option: Selectable<T>) {
  return modelValueSet.value.has(option.value);
}

function deselect(option: Selectable<T>) {
  emit(
    'update:modelValue',
    props?.modelValue?.filter((selectedValue) => selectedValue.value !== option.value) || []
  );
}

function deselectFromBadge(option: Selectable<T>) {
  deselect(option);

  if (!isOpen.value) {
    return focusOnTrigger();
  }

  focusOnInput();
}

function handleModelValueUpdate(value) {
  if (!Array.isArray(value)) {
    emit('update:modelValue', [value]);
    return;
  }

  emit('update:modelValue', value);
}

function handleSelectAll() {
  if (areAllSelected.value) {
    handleModelValueUpdate([]);
  } else {
    const currentlyNotSelected = props.options.filter((o) => !modelValueSet.value.has(o.value));
    const all = (props.modelValue ?? []).concat(...currentlyNotSelected);
    handleModelValueUpdate(all);
  }
}

function closeCombobox() {
  isOpen.value = false;
}

function toggleTruncatingBadges() {
  isTruncatingBadges.value = !isTruncatingBadges.value;
}
</script>

<template>
  <ComboboxRoot
    multiple
    v-model:open="isOpen"
    :modelValue="modelValue"
    @update:modelValue="handleModelValueUpdate"
    @update:open="$emit('open')"
    :filterFunction="(opts) => opts"
    :disabled="isDisabled || isReadonly"
  >
    <div class="flex w-full items-start gap-x-2">
      <ComboboxAnchor class="w-full min-w-0">
        <ComboboxTrigger
          v-bind="$attrs"
          ref="triggerElement"
          tabindex="0"
          :class="[...triggerClasses, 'group', 'text-sm']"
          v-on="triggerEvents"
        >
          <ul
            class="flex min-w-0 flex-1 items-center gap-1 overflow-x-clip rounded-lg"
            :class="{
              'flex-wrap': !isTruncatingBadges
            }"
            ref="overflowBoundaryElement"
          >
            <li v-if="(modelValue?.length ?? 0) === 0" class="pl-1.5 text-sm text-slate-400">
              {{ placeholder }}
            </li>
            <template v-if="selectAll && selectAllViewAs === 'single' && areAllSelected">
              <Badge
                variant="soft"
                shape="rounded"
                size="md"
                :class="[
                  'whitespace-nowrap border-none font-semibold text-slate-700',
                  isDisabled && 'pointer-events-none'
                ]"
                tabindex="-1"
              >
                {{ selectAllLabel }}
              </Badge>
            </template>

            <template v-else v-for="(selectable, i) in modelValue" :key="selectable.value">
              <li class="relative">
                <Badge
                  v-if="isFirstInvisibleBadge(selectable) && isTruncatingBadges"
                  :label="`+${orderedOverflowingSelections.length}`"
                  variant="soft"
                  shape="rounded"
                  size="md"
                  class="absolute border-none font-semibold text-slate-700"
                  tabindex="-1"
                  v-intersection-observer="[
                    (observerEntries: IntersectionObserverEntry[]) =>
                      onFirstBadgeIntersection(selectable, i, observerEntries),
                    { root: overflowBoundaryElement, threshold: 1 }
                  ]"
                />
                <Badge
                  variant="soft"
                  shape="rounded"
                  size="md"
                  :class="[
                    'invisible whitespace-nowrap border-none font-semibold text-slate-700',
                    !isBadgeOverflowing(selectable) && '!visible',
                    isDisabled && 'pointer-events-none'
                  ]"
                  tabindex="-1"
                  :clearable="!isDisabled && !isReadonly"
                  @onClear="() => deselectFromBadge(selectable)"
                >
                  {{ selectable.label }}
                </Badge>
                <span
                  aria-hidden="true"
                  v-intersection-observer="[
                    (observerEntries: IntersectionObserverEntry[]) =>
                      onBadgeIntersection(selectable, i, observerEntries),
                    { root: overflowBoundaryElement }
                  ]"
                />
              </li>
            </template>
          </ul>
          <LockIcon
            v-if="isReadonly === true"
            class="h-4.5 w-4.5 shrink-0 text-slate-500 text-slate-500/25"
          />
          <AngleDown v-else class="h-4.5 w-4.5 text-slate-500" />
        </ComboboxTrigger>
      </ComboboxAnchor>
      <div class="flex h-[42px] grow-0 items-center md:h-8">
        <IconButton
          v-if="!isTruncatingBadges"
          :icon="BringBottom"
          ariaLabel="Show all selected options"
          :size="ButtonSize.md"
          variant="invisible"
          @click.stop="toggleTruncatingBadges"
        />
        <IconButton
          v-if="isTruncatingBadges"
          :icon="BringFront"
          :isDisabled="!orderedOverflowingSelections.length"
          ariaLabel="Hide all selected options"
          :size="ButtonSize.md"
          variant="invisible"
          @click.stop="toggleTruncatingBadges"
        />
      </div>
    </div>

    <ComboboxPortal>
      <ComboboxContent
        align="start"
        :sideOffset="4"
        position="popper"
        @escapeKeyDown="focusOnTrigger"
        @interactOutside="focusOnTrigger"
        class="min-w-[--radix-combobox-trigger-width] rounded-lg border border-slate-200 bg-white drop-shadow-md"
        :style="{ zIndex }"
      >
        <ComboboxSearchInput
          v-if="searchable"
          ref="searchInputElement"
          :searchQuery="searchQuery"
          :isLoading="isLoading"
          @searchQuery="handleSearchQuery"
        />

        <SelectBoxViewport :as="ComboboxViewport">
          <slot name="options">
            <SelectBoxItem
              v-if="selectAll && !hasNoResultsForQuery && options.length"
              :as="ComboboxItem"
              value="SELECT_ALL"
              @select.prevent
              @click="handleSelectAll"
              :checked="areAllSelected"
            >
              <template #default="{ checked }">
                <Checkbox :checked="checked" />
                <p>{{ selectAllLabel }}</p>
              </template>
            </SelectBoxItem>
            <SelectBoxItem
              :as="ComboboxItem"
              v-for="option in filteredOptions"
              :class="{
                'ml-4': option.header
              }"
              :key="option.value?.toString() ?? option.label"
              :value="option"
              :disabled="option.disabled"
              :checked="isSelected(option)"
              @click="focusOnInput"
            >
              <template #default="{ checked }">
                <slot name="option" :checked :option :close="closeCombobox">
                  <Checkbox :checked="checked" />
                  <div>
                    <p>{{ option.label }}</p>
                    <p v-if="option.description" class="text-2xs text-slate-400">
                      {{ option.description }}
                    </p>
                  </div>
                </slot>
              </template>
            </SelectBoxItem>
          </slot>
          <slot name="noResults">
            <NoResults v-if="hasNoResultsForQuery" />
            <NoOptions v-else-if="!searchQuery && !options.length" />
          </slot>
        </SelectBoxViewport>
      </ComboboxContent>
    </ComboboxPortal>
  </ComboboxRoot>
</template>
