<script setup lang="ts">
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Button from '@/components/button/Button.vue';

import IconCheck from '@/icons/line/check.svg';

defineProps<{
  email: string;
}>();
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper textAlignment="center" title="Link sent! Check your inbox!">
    <template #description>
      We’ll send a forgotten password link to <strong class="font-semibold">{{ email }}</strong> if
      it exists in our system.
    </template>
    <template #header>
      <div class="flex items-center justify-center">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <IconCheck class="mx-auto h-12 w-12 text-slate-500" />
        </div>
      </div>
    </template>
    <Button
      :color="ButtonColor.primary"
      :href="route('login.show')"
      :size="ButtonSize.lg"
      :variant="ButtonVariant.solid"
      class="w-full justify-center"
      >Back to login</Button
    >
  </AuthFormWrapper>
</template>
