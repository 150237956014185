<script setup lang="ts">
import { BadgeSize, BadgeVariant } from '@/hooks/useBadgeClasses';
import { ButtonColor, ButtonSize, ButtonVariant } from '@/hooks/useButtonClasses';

import AuthForm from '@/components/form/AuthForm.vue';
import AuthFormWrapper from '@/components/auth/AuthFormWrapper.vue';
import Badge from '@/components/badge/Badge.vue';
import Button from '@/components/button/Button.vue';
import IconButton from '@/components/button/IconButton.vue';
import StackedFormFooter from '@/components/formFooter/StackedFormFooter.vue';

import IconAngleRight from '@/icons/line/angle-right.svg';
import IconCommentAltMessage from '@/icons/line/comment-alt-message.svg';
import IconEnvelope from '@/icons/line/envelope.svg';
import IconHash from '@/icons/line/hash.svg';
import IconArrowLeft from '@/icons/line/arrow-left.svg';

defineProps<{
  channels: Array<App.Auth.Data.TwoFactorChannelData>;
}>();

const sendCode = useForm({
  method: 'POST',
  fields: {}
});

function channelIcon(channelType: string): Component {
  switch (channelType) {
    case 'email':
      return IconEnvelope;
  }
  return IconCommentAltMessage;
}

function handleSendCode(selectedChannel: App.Auth.Data.TwoFactorChannelData) {
  sendCode.submitWith({
    url: route('two-factor.deliver-code.store', {
      channel: selectedChannel.id
    })
  });
}
</script>


					<script lang="ts">
					import __hybridly_layout_0 from '~/resources/domains/auth/layouts/two-columns-guest.vue';
					export default { layout: [__hybridly_layout_0] }
					</script>
					<template>
				
  <AuthFormWrapper
    title="Two-factor authentication"
    textAlignment="center"
    description="Two factor authentication protects your account by requiring an additional code when you log in on a device that we don’t recognise."
  >
    <template #header>
      <div class="flex items-center justify-center">
        <div class="flex h-28 w-28 items-center rounded-full bg-slate-100">
          <IconHash class="mx-auto h-12 w-12 text-slate-500" />
        </div>
      </div>
    </template>
    <IconButton
      :color="ButtonColor.slate"
      :href="route('login.show')"
      :icon="IconArrowLeft"
      :size="ButtonSize.lg"
      :variant="ButtonVariant.invisible"
      ariaLabel="`Back to login`"
      class="absolute left-5 top-5 xs:left-6 xs:top-6 sm:left-10 sm:top-10 2xl:left-16 2xl:top-16"
      useMediaQuery
    />
    <AuthForm>
      <div class="space-y-5">
        <h2 class="text-2xs font-bold uppercase text-slate-500">Choose your security method</h2>
        <ul class="flex w-full flex-col gap-3">
          <li v-for="channel in channels" :key="channel.id">
            <Button
              :color="ButtonColor.slate"
              :size="ButtonSize.lg"
              :variant="ButtonVariant.outlined"
              class="w-full text-left"
              @click="handleSendCode(channel)"
            >
              <template #withoutWrapper>
                <div class="flex items-center gap-2">
                  <div class="flex h-7 w-7 items-center justify-center rounded-full bg-slate-100">
                    <component
                      :is="channelIcon(channel.channelType)"
                      class="h-4 w-4 text-slate-700"
                    />
                  </div>
                  <span>{{ channel.redactedDestination }}</span>
                  <Badge
                    :variant="BadgeVariant.solid"
                    :label="channel.channelTypeDisplayName"
                    :size="BadgeSize.xs"
                  />
                </div>
                <span class="flex flex-1 justify-end">
                  <IconAngleRight class="h-4 w-4" />
                </span>
              </template>
            </Button>
          </li>
        </ul>
      </div>
      <template #footer>
        <StackedFormFooter>
          <Button
            :color="ButtonColor.slate"
            :size="ButtonSize.lg"
            :variant="ButtonVariant.invisible"
            useMediaQuery
            @click="router.post(route('logout.store'))"
          >
            Sign out
          </Button>
        </StackedFormFooter>
      </template>
    </AuthForm>
  </AuthFormWrapper>
</template>
